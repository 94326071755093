// eslint-disable-next-line filenames/match-exported
import blue from '@material-ui/core/colors/blue';
import blueGrey from '@material-ui/core/colors/blueGrey';
import {alpha} from '@material-ui/core/styles';

const defaultPalette = mode => ({
  mode,
  common: {
    black: 'rgba(0, 0, 0, 1)',
    white: '#FFFFFF'
    //TODO: remove colors in future
    //Do not use
    // primary: '#142241',
    // secondary: '#e9b109'
  },
  background: {
    paper: '#fff',
    default: '#fafafa'
  },
  primary: {
    light: '#E2EFFF',
    main: '#142241',
    darkFaded: '#2C3954',
    dark: '#161F32',
    bg: '#0e1729',
    contrastText: '#fff'
  },
  secondary: {
    light: '#FAC937',
    main: '#E9B109',
    dark: '#E7AD00',
    contrastText: '#fff'
  },
  error: {
    light: '#FF5252',
    main: '#EA4335',
    dark: '#D80027',
    contrastText: '#fff'
  },
  success: {
    main: '#52D275'
  },
  text: {
    primary: '#D9D9D9',
    secondary: 'rgba(255, 255, 255, 1)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)'
  },

  divider: alpha('#E2EFFF', 0.1),
  link: blue[800],
  icon: blueGrey[600],

  colors: {
    faQQuestion: '#BDDBFF',
    background: '#0F1729',
    fadedText: 'rgba(255, 255, 255, 0.5)',
    grey1: '#C4C7CF',
    border: '#5390DA',
    borderModal: '#5F6572',
    borderLight: '#47668B',
    borderSection: '#2B364E',
    primaryButton: '#E8E9EC',
    inputBorder: '#0E3B7D',
    inputActiveBorder: '#445579',
    inputInDropdownBorderActive: '#172D58',
    divider: '#2C3E67',
    dividerLight: alpha('#ffffff', 0.1),
    dividerGrey: '#303747',
    checkbox: '#37435B',
    counter: '#2C3852',
    counterHovered: '#4C5872',
    counterBorder: '#38445E',
    skeleton: '#142241',
    tooltip: '#323F5C',
    cart: '#324777',
    cartLight: '#788DBD',
    iconButtonPrimary: '#656D7F',
    faq: '#1B2335',
    coupon: '#EDC13A',
    section: '#17223A',
    cardItem: '#232F49',
    drawer: '#19253F',

    inputOutlined: '#414D65',
    inputOutlinedActive: '#74B1FB',
    inputOutlinedHover: '#737F97',
    inputDisabled: '#D9D9D9',

    authModal: '#242C3E',
    iconButton: 'rgba(68, 68, 68, 0.50)',

    primaryBlue500: '#455376',

    green: {
      900: '#52D275',
      800: '#00B67A'
    },
    yellow: {
      900: '#E9B109',
      800: '#EDC13A',
      700: '#FF9A02',
      600: '#DAB473'
    },
    blue: {
      100: '#A3ADC4',
      200: '#8992AC',
      400: '#5A6687',
      500: '#455376',
      600: '#38445E',
      700: '#232F49',
      800: '#17223A',
      900: '#0F1729'
    },
    lightBlue: {
      600: '#74B1FB'
    },
    grey: '#D9D9D9'
  },
  backgrounds: {
    coupon: '#EDC13A',
    tooltip: '#323F5C',
    modalHeader: '#475778',
    disabled: '#314261',
    modal: '#2C3954',
    box: '#243354',
    text: '#1B2741',
    text2: '#213353',
    cardItem: '#232F49',
    counter: '#303C56',
    input: '#162138',
    inputInDropdown: '#1A253C',
    inputInDropdownActive: '#0C172E',
    section: '#17223A',
    menuItem: '#1A263E',

    dark: '#0F1729',
    darkHover: '#0E1A30',

    cart: '#324777',
    cartLight: '#788DBD'
  },
  starsRating: {
    0: '#dcdce6',
    1: '#f64028',
    2: '#fa852a',
    3: '#ffcf2e',
    4: '#73cf17',
    5: '#219653'
  }
});

export default defaultPalette;
