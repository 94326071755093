import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {PageNext} from 'src/componentsNext/PageNext';
import {StepsSection} from 'src/views/GameItemsNext/sections/StepsSection/StepsSection';
import {ReviewsSection} from 'src/views/GameItemsNext/sections/ReviewsSection';
import {TextSection} from 'src/views/GameItemsNext/sections/TextSection';
import useStorefrontData from 'src/hooks/useStorefrontData';
import {FaqSection} from 'src/views/GameItemsNext/sections/FaqSection/FaqSection';
import {PaymentMethodsSection} from 'src/views/GameItemsNext/sections/PaymentMethodsSection';

import {schemaOrg} from './shemaOrg';
import {TopSection} from './sections/TopSection';
import {buyingGoldSteps} from './constants';
import {WhyChooseSection} from './sections/WhyChooseSection';

const useStyles = makeStyles(theme => ({
  contentBox: {
    backgroundColor: theme.palette.backgrounds.dark,
    // paddingBottom: theme.spacing(9),
    marginTop: -theme.spacing(10),

    [theme.breakpoints.down('sm')]: {paddingTop: -theme.spacing(10)}
  },
  commonBgWrapperTop: {
    // minHeight: 732,
    // backgroundImage: 'url("/images/imagesNext/goldPages/bannerBgDesktop.png")',
    backgroundImage:
      'url("/images/imagesNext/boostingPage/boostingBanner.png")',
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',
    backgroundColor: theme.palette.backgrounds.dark,
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      // backgroundImage: 'url("/images/imagesNext/goldPages/bannerBgMobile.png")'
      backgroundImage: 'url("/images/imagesNext/boostingPage/bannerMobile.png")'

      // 'radial-gradient(154.29% 89.02% at 50% 10.98%, rgba(15, 23, 41, 0.72) 0%, rgba(15, 23, 41, 0.58) 23%, rgba(15, 23, 41, 0.75) 53.7%, #0F1729 100%), url("/images/imagesNext/boostingPage/bannerMobile.png")'
    }
  },
  commonBgWrapperMiddle: {
    // height: 1038,
    backgroundImage:
      'url("/images/imagesNext/itemsPage/itemsMiddleBgDesktop.png")',
    backgroundPosition: 'left top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% auto',

    [theme.breakpoints.down('xs')]: {
      backgroundImage:
        'url("/images/imagesNext/itemsPage/itemsMiddleBgMobile.png")'
    }
  }
}));

export const HomePageNext = ({reviews, products, testimonials}) => {
  const {page} = useStorefrontData();
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <PageNext
      schemaOrg={schemaOrg}
      reviews={reviews}
      faqSchema
      breadcrumbsSchema
    >
      <div className={clsx(classes.contentBox)}>
        <div className={classes.commonBgWrapperTop}>
          <TopSection products={products} />
          <StepsSection
            title='How to Buy RS Gold'
            subtitle='IN 4 EASY STEPS'
            steps={buyingGoldSteps}
          />
        </div>
        <div className={classes.commonBgWrapperMiddle}>
          <ReviewsSection testimonials={testimonials} />
          <WhyChooseSection mt={isDownSm ? 48 : 72} mb={isDownSm ? 64 : 24} />
          <TextSection page={page} />
        </div>
        <FaqSection isBanner page={page} />
        <PaymentMethodsSection />
      </div>
    </PageNext>
  );
};

HomePageNext.proptypes = {
  reviews: PropTypes.object,
  products: PropTypes.object
};
