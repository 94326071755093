import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(theme => ({
  sectionHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: 0,
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  line: {
    width: 72,
    height: 4,
    borderRadius: 8,
    backgroundColor: theme.palette.secondary.main
  }
}));

export const SectionHeader = ({title, subtitle, mb}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div
      className={classes.sectionHeader}
      style={{marginBottom: mb || 'unset'}}
    >
      <Typography
        style={{
          ...(isDownSm
            ? extendedTypography.h2Mobile
            : extendedTypography.h2Desktop)
        }}
      >
        {title}
      </Typography>
      <div className={classes.line} />
      {subtitle && (
        <Typography style={extendedTypography.h5MediumDesktop}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  mb: PropTypes.number
};
