import {Alert, AlertTitle} from '@material-ui/lab';
import React from 'react';
import dynamic from 'next/dynamic';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const RichTextContent = dynamic(
  () =>
    import('src/components/RichTextContent').then(mod => mod.RichTextContent),
  {ssr: false}
);

const useStyles = makeStyles(theme => ({
  textSection: {
    maxWidth: 1300 + theme.spacing(4),
    width: '100%',
    padding: theme.spacing(0, 2),
    margin: [theme.spacing(9, 'auto', 0, 'auto')],
    lineHeight: 1.5,

    '& img': {
      borderRadius: 8
    },

    [theme.breakpoints.down('sm')]: {
      margin: [theme.spacing(6, 'auto', 0, 'auto')]
    }
  },
  center: {
    justifyContent: 'center'
  }
}));

export const TextSection = ({page}) => {
  const classes = useStyles();

  return (
    <div className={classes.textSection}>
      {!page.isPublished && (
        <Alert severity='warning' className={classes.center}>
          <AlertTitle style={{textAlign: 'center'}}>
            WARNING! This page is not published yet!
          </AlertTitle>
        </Alert>
      )}
      <RichTextContent htmlContent={page.content} />
    </div>
  );
};

TextSection.propTypes = {
  page: PropTypes.object
};
